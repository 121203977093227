










import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SimpleButton',
  props: {
    buttonType: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
});
