






































import {
  computed,
  defineComponent, PropType, Ref, toRef,
} from '@nuxtjs/composition-api';

import { useTax } from '~/composables';
import { ProductPrices } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'ProductPrice',
  props: {
    prices: {
      type: Object as PropType<ProductPrices>,
      required: true,
    },
    specialPrice: {
      type: Number,
      default: null,
    },
    isPriceBold: {
      type: Boolean,
      default: true,
    },
    isListing: {
      type: Boolean,
      default: false,
    },
    isNoLeading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const price: Ref<ProductPrices> = toRef(props, 'prices');
    const { vatLabel, getCalculatedPrice } = useTax();
    const originalPrice = computed(() => getCalculatedPrice(price.value.regularPrice));
    const finalPrice = computed(() => getCalculatedPrice(price.value.minimalPrice));

    return {
      originalPrice,
      finalPrice,
      vatLabel,
    };
  },
});
